import React from "react";

function BestSeller({ props }) {
  return (
    <div className="flex justify-evenly md:justify-between gap-4 items-center flex-wrap">
      {props.map((item, index) => {
        return (
          <div
            key={index}
            className="w-[45%] md:w-56 h-44 flex justify-center items-center p-4 transition-all rounded-xl"
          >
            <img
              src={item}
              alt={`Best Seller ${index}`}
              className="w-full h-full object-contain"
            />
          </div>
        );
      })}
    </div>
  );
}
export default BestSeller;
