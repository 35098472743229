import React from "react";
import CategorySlider from "../components/CategorySlider";
import BannerSlider from "../components/BannerSlider";
import BestSeller from "../components/BestSeller";
import HomeAppliances from "../components/HomeAppliances";
// import {BestSellerImage1} from "/assets/homepage/bestSeller1.png"

function Home() {
  const BestSellerImage1 = [
    "/assets/homepage/bestSeller1.png",
    "/assets/homepage/bestSeller2.png",
    "/assets/homepage/bestSeller3.png",
    "/assets/homepage/bestSeller4.png",
    "/assets/homepage/bestSeller5.png",
  ];
  const kitchen1 = [
    "/assets/homepage/kitchen1.png",
    "/assets/homepage/kitchen2.png",
    "/assets/homepage/kitchen3.png",
    "/assets/homepage/kitchen4.png",
    "/assets/homepage/kitchen5.png",
    // "/assets/homepage/kitchen6.png",
  ];
  const bannerImg = [
    "https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/dff6511cbf3c625e.jpg?q=20",
    "https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/2ea57ef59b0820b3.jpg?q=20",
    "https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/b1ba982123cb572a.jpg?q=20",
    "https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/875690a28393fe66.jpg?q=20",
    "https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/89f8eeb28f03fe04.jpg?q=20",
  ];

  const homeAppliances = [
    {
      img: "/assets/homepage/homeAppliances1.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "899/-",
    },
    {
      img: "/assets/homepage/homeAppliances2.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "1099/-",
    },
    {
      img: "/assets/homepage/homeAppliances1.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "599/-",
    },
    {
      img: "/assets/homepage/homeAppliances3.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "699/-",
    },
    {
      img: "/assets/homepage/homeAppliances4.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "1199/-",
    },
    {
      img: "/assets/homepage/homeAppliances5.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "799/-",
    },
  ];

  const browsingHistory = [
    {
      img: "/assets/homepage/browsinghistory1.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "2399/-",
    },
    {
      img: "/assets/homepage/browsinghistory2.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "5099/-",
    },
    {
      img: "/assets/homepage/browsinghistory3.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "3599/-",
    },
    {
      img: "/assets/homepage/browsinghistory4.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "699/-",
    },
    {
      img: "/assets/homepage/browsinghistory5.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "5199/-",
    },
    {
      img: "/assets/homepage/browsinghistory6.png",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "8999/-",
    },
  ];

  return (
    <div className="w-full">
      <main className="my-1">
        <CategorySlider />
      </main>
      <BannerSlider banner={bannerImg} />
      <div className="bg-white p-2 py-4 md:p-6 my-7 shadow-md">
        <h2 className="font-bold text-sm md:text-base mb-5">
          Up to 70% Off | BestSeller from New businesses
        </h2>
        <BestSeller props={BestSellerImage1} />
      </div>

      {/* {best selller women start} */}
      <div className="p-2 py-4 md:px-6 w-full my-7">
        <div className="flex gap-4 justify-between h-auto md:h-96 flex-wrap w-full md:flex-nowrap">
          <div className="w-full md:w-1/3 h-full p-4 border shadow-md bg-white rounded-md">
            <h3 className="font-bold text-sm md:text-base mb-3">
              Women's Fashion
            </h3>
            <div className="relative w-full h-80">
              {" "}
              {/* Ensure the container has a fixed height */}
              <img
                src="/assets/homepage/bestsellerwomen1.png"
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          <div className="w-full md:2/3 h-full flex flex-col gap-y-4">
            <div className="w-full h-full md:h-1/2  bg-white shadow-md border rounded-md p-2">
              <h2 className="text-sm mb-2">
                Up to 80% Off | Latest Product for Women's
              </h2>
              <div className="flex justify-between w-full items-center flex-wrap md:flex-nowrap gap-3">
                <div className="w-full md:w-1/2 flex justify-around items-center">
                  <div className="">
                    <img src="/assets/homepage/bestsellerwomen2.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen3.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen4.png" alt=""/>
                  </div>
                </div>
                <div className="w-full md:w-1/2 flex justify-around items-center">
                  <div className="">
                    <img src="/assets/homepage/bestsellerwomen5.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen6.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen7.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-full md:h-1/2 shadow-md border rounded-md bg-white p-2">
              <h2 className="text-sm mb-2">
                Up to 86% Off | Best Sell for Customer
              </h2>
              <div className="flex justify-between w-full items-center flex-wrap md:flex-nowrap gap-3">
                <div className="w-full md:w-1/2 flex justify-around items-center">
                  <div className="">
                    <img src="/assets/homepage/bestsellerwomen8.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen9.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen10.png" alt=""/>
                  </div>
                </div>
                <div className="w-full md:w-1/2 flex justify-around items-center">
                  <div className="">
                    <img src="/assets/homepage/bestsellerwomen11.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen12.png" alt=""/>
                  </div>
                  <div>
                    <img src="/assets/homepage/bestsellerwomen13.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {best selller women start} */}

      <div className="bg-white p-2 py-4 md:p-6 my-6 shadow-md">
        <h2 className="font-bold text-sm md:text-base mb-3">Home Appliances</h2>
        <HomeAppliances appliances={homeAppliances} />
      </div>

      <div className="p-2 py-4 md:px-6 w-full">
        <div className="w-full flex-wrap md:flex-nowrap gap-3 flex justify-between h-full">
          <div className="w-full md:w-1/3 flex flex-col gap-3">
            <div className="flex-1 p-4 border shadow-lg bg-white rounded-lg">
              <h1 className="text-base md:text-lg">
                Appliances for your home Up to 55% off
              </h1>
              <div className="mt-3 flex justify-evenly items-center gap-3 flex-wrap">
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances1.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances2.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="mt-3 flex justify-evenly items-center gap-3 flex-wrap">
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances3.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances4.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 p-4 border shadow-lg bg-white rounded-lg">
              <h1 className="text-base md:text-lg">
                Appliances for your home Up to 55% off
              </h1>
              <div className="mt-3 flex justify-evenly items-center gap-3 flex-wrap">
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances5.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
                <div className="w-28 flex justify-center items-center">
                  <img
                    src="/assets/homepage/watchAppliances6.png"
                    alt="kitchen"
                    className="w-full"
                  />
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="w-full md:w-2/3 h-full flex items-center justify-center">
            <img
              src="/assets/homepage/watchBanner3.png"
              alt=""
              className="h-full md:h-[500px] w-full"
            />
          </div>
        </div>
      </div>

      <div className="p-2 py-4 md:px-6 w-full my-6">
        <div className="w-full flex gap-5 flex-wrap md:flex-nowrap">
          <div className="w-full md:w-1/3 border shadow-md rounded-md overflow-hidden bg-white p-5 flex justify-start items-baseline flex-col gap-5">
            <h2 className=" text-sm md:text-base font-bold mb-5">
              Starting ₹99 | Start your fitness journey
            </h2>
            <img
              src="/assets/homepage/fitness1.png"
              alt=""
              className=" w-full h-72"
            />
          </div>

          <div className="w-full md:w-1/3 border shadow-md rounded-md overflow-hidden bg-white p-5 flex justify-start items-baseline flex-col gap-5">
            <h2 className=" text-sm md:text-base font-bold mb-5">
              Essentials for Expecting Mothers
            </h2>
            <div className=" w-full flex gap-5 justify-around">
              <div>
                <img src="/assets/homepage/pillows1.png" alt="" />
              </div>
              <div>
                <img src="/assets/homepage/pillows2.png" alt="" />
              </div>
            </div>
            <div className=" w-full flex gap-5 justify-around">
              <div>
                <img src="/assets/homepage/pillows4.png" alt="" />
              </div>
              <div>
                <img src="/assets/homepage/pillows3.png" alt="" />
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 border shadow-md rounded-md overflow-hidden bg-white p-5 flex justify-start items-baseline flex-col gap-5">
            <h2 className=" text-sm md:text-base font-bold mb-5">
              One stop shop for all your wedding shopping
            </h2>
            <div className=" w-full flex gap-5 justify-around">
              <div>
                <img src="/assets/homepage/card1.png" alt="" />
              </div>
              <div>
                <img src="/assets/homepage/card2.png" alt="" />
              </div>
            </div>
            <div className=" w-full flex gap-5 justify-around">
              <div>
                <img src="/assets/homepage/card3.png" alt="" />
              </div>
              <div>
                <img src="/assets/homepage/card4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-2 py-4 md:p-6 my-7 shadow-md">
        <h2 className="font-bold text-sm md:text-base mb-5">
          Best Sells in Home & Kitchen
        </h2>
        <BestSeller props={kitchen1} />
      </div>

      <div className="p-2 py-4 md:px-6 w-full my-7">
        <div className="flex gap-5 justify-between flex-wrap md:flex-nowrap">
          <div className="w-full md:w-1/2 shadow-md">
            <img
              src="/assets/homepage/ComfortBag1.png"
              alt=""
              className="w-full"
            />
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/homepage/PerfectCap.png"
              alt=""
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-2 py-4 md:p-6 my-7 shadow-md">
        <h2 className="font-bold text-sm md:text-base mb-3">Home Appliances</h2>
        <HomeAppliances appliances={browsingHistory} />
      </div>
    </div>
  );
}

export default Home;
