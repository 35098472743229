import React from "react";

function HomeAppliances({ appliances }) {
  // console.log(Appliances)
  return (
    <div className="flex justify-evenly md:justify-between gap-4 items-center flex-wrap">
      {appliances.map((item, index) => (
        <div key={index} className="w-[45%] md:w-44 flex justify-center items-center flex-col p-4 hover:shadow-md hover:shadow-slate-400 transition-all rounded-xl">
          <div>
            <img src={item.img} alt={item.name} className="w-full h-full"/>
          </div>
          <p className="text-justify text-sm mt-1 w-full">{item.name.slice(0,40)}...</p>
          <p className="text-center text-emerald-800 text-lg mt-1">{item.price}</p>
        </div>
      ))}
    </div>
  );
}

export default HomeAppliances;
