import React from "react";
import { Link } from "react-router-dom";

function Productlist({ product }) {
  return (
    <>
      <div className="w-full bg-white flex justify-center md:justify-between gap-5 flex-wrap p-4 ">
        {product.map((item, index) => {
          return (
            <Link
              to={`/product/${item.brand}/${item.id}`}
              className="bg-transparent w-40 md:w-52 flex flex-col justify-center items-center gap-y-3 p-2 rounded-md border"
            >
              <div className="flex justify-center items-center w-full h-36 md:h-48">
                <img src={item.img} alt="" className="duration-200 hover:scale-110 w-full h-full" />
              </div>
              <div className="p-2">
                <p className="w-full font-semibold mb-2">{item.productName.slice(0,30)}...</p>
                <p className="">
                  <b className="mr-1 text-lg text-orange-500">₹{item.finalprice}/-</b>
                  <del className="font-sembold text-gray-500 text-sm">
                  ₹{item.price}
                  </del>{" "}
                  <span className="text-green-500">
                    {item.discount}
                     &nbsp;Off{" "}
                  </span>
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default Productlist;
