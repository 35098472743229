import React from "react";

function Contact() {
  return (
    <>
      <div className="w-full">
        <img
          src="/assets/contact2.jpg"
          alt=""
          className="w-full md:h-40 object-contain md:object-cover"
        />
      </div>
      <div className="p-2 py-4 md:p-6 my-3 w-full lg:w-3/4 flex justify-betwween items-start mx-auto gap-7 flex-wrap md:flex-nowrap">
        <div className="w-full md:w-1/2">
          <form action="" className="w-full">
            <div className="flex flex-col mb-4">
              <input
                type="text"
                placeholder="Username.."
                name="user"
                required
                className="bg-white p-2 outline-none rounded-md text-sm border border-emerald-800"
              />
            </div>
            <div className="flex flex-col mb-4">
              <input
                type="text"
                placeholder="Phone no.."
                name="phone"
                required
                className="p-2 outline-none rounded-md text-sm border border-emerald-800"
              />
            </div>

            <div className="flex flex-col mb-4">
              <input
                type="email"
                placeholder="Enter your email address"
                id="email"
                name="email"
                required
                className="p-2 outline-none rounded-md text-sm border border-emerald-800"
              />
            </div>
            <div className="flex flex-col mb-4">
              <textarea
                rows={5}
                required
                placeholder="Enter your message..."
                className="p-2 outline-none rounded-md text-sm border border-emerald-800"
              ></textarea>
            </div>

            <div className="w-full border rounded-md mb-4 overflow-hidden">
              <button className="w-full py-[7px] text-sm text-center bg-emerald-800 text-white hover:bg-emerald-900 duration-300">
                submit
              </button>
            </div>
          </form>
        </div>
        <div className="w-full md:w-1/2 flex flex-col gap-3 items-start justify-start">
          <h2 className="text-xl pb-1 font-bold underline">Contact Us</h2>
          <div className="flex flex-col gap-1">
            <p className="text-start text-md">info@markletechandmedia.com</p>
            <p className="text-start text-md">markletechandmedia@gmail.com</p>
            <p className="text-start text-md">
              +91-798-233-2070 /+91-767-810-2159
            </p>
          </div>
          <h2 className="text-start text-md">
            A-52, som bazar chowk, vikas nagar
            uttam nagar ,new delhi-59 (INDIA)
          </h2>
        </div>
      </div>
    </>
  );
}

export default Contact;
