import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";

function Baby_product() {
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg",
  ];

  const subCategory=["Baby Care","Baby clothing","Baby safety","Feeding","Baby shoes","Gifts Pack","Maternity","Nursery","More"]
  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white my-7">
        {
          subCategory.map((item,index)=>
            <div key={index}>{item}</div>
          )
        }
      </div>
      
    </div>
  );
}

export default Baby_product;


